import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from './plugins/i18n'
import router from './plugins/router'
import store from './plugins/store'
import reactiveStorage from "./plugins/reactiveStorage";
import { injectStorage } from 'vue-reactive-storages';

Vue.config.productionTip = false;

const app = {
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App)
}

injectStorage(Vue, app, '$RStore', reactiveStorage);

new Vue(app).$mount('#app');

