import {set, toggle} from '@/utils/vuex'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        drawer: null,
        alert: false,
        alertType: 'success',
        alertText: '',
        login: false,
        allowedAdvocardServiceTypesForChevalier: ["11", "15", "28"] // "16" ruhender Verkehr wurde entfernt
    },
    mutations: {
        setDrawer: set('drawer'),
        toggleDrawer: toggle('drawer'),

        setAlert: set('alert'),
        setAlertText: set('alertText'),
        setAlertType: set('alertType'),

        setLogin: set('login'),
    }
})
