import Keycloak from "keycloak-js";
import { keycloakConfig } from "@/configBuilder";
import jwtDecode from "jwt-decode";
import reactiveStorage from "@/plugins/reactiveStorage";
import {getAllLawyers} from "@/plugins/permission";

// Helper START
function setPermissions() {
    let permissions = {
        admin: false,
        moderator: false,
        user: false,
    };

    permissions.admin = keycloakInstance.hasResourceRole("admin");
    permissions.moderator = keycloakInstance.hasResourceRole("moderator");
    permissions.user = keycloakInstance.hasResourceRole("user");

    if (permissions.moderator) {
        permissions.admin = true; // nur für export
        //mod scuffed setup fix
    }

    reactiveStorage.user.roles.Admin = permissions.admin;
    reactiveStorage.user.roles.User = permissions.user;
    reactiveStorage.user.roles.Moderator = permissions.moderator;

    // console.log("Permissions: ", permissions);
    // console.log("Roles: ", keycloakInstance.resourceAccess.roles);
    // console.log("Admin: ", keycloakInstance.hasResourceRole("admin"), "Mod: " +  keycloakInstance.hasResourceRole("lawyer"), "User: "+ keycloakInstance.hasResourceRole("user"));
}
function setLocalStorage() {
    reactiveStorage.user.KeycloakUUID = jwtDecode(keycloakInstance.token)["sub"];
    reactiveStorage.user.Username = jwtDecode(keycloakInstance.token)["preferred_username"];
    reactiveStorage.app.AuthenticationType = "Bearer"; // TODO: Change to keycloak
}
// HELPER END

const keycloakInstance = new Keycloak(keycloakConfig);

let isAuthenticated;
const IsAuthenticated = () => isAuthenticated;

const Login = async () => {
    // console.log("Login: Called");
    try {
        const authenticated = await keycloakInstance.init({ onLoad: "login-required" });
        isAuthenticated = authenticated;
    } catch (error) {
        // can only be init once error is irrelevant
    } finally {
        setPermissions();
        setLocalStorage();
        await getAllLawyers()
    }
};

const UserName = () =>
    keycloakInstance?.tokenParsed?.preferred_username;

const Token = async () => {
    await keycloakInstance.updateToken(1);
    return keycloakInstance.token;
};

const IsTokenValid = () => keycloakInstance?.isTokenExpired(10);

const Logout = () => keycloakInstance.logout();

const UserRoles = () => {
    if (keycloakInstance.resourceAccess === undefined) return undefined;
    return keycloakInstance.resourceAccess[keycloakConfig.clientId].roles;
};

const KeyCloakService = {
    CallLogin: Login,
    GetUserName: UserName,
    GetAccessToken: Token,
    CallLogout: Logout,
    GetUserRoles: UserRoles,
    GetIsAuthenticated: IsAuthenticated,
    GetIsTokenValid: IsTokenValid,
};

keycloakInstance.onTokenExpired = () => {
    console.log("Token expired");
    keycloakInstance
        .updateToken(1)
        .then((refreshed) => {
            if (refreshed) {
                console.log("Token refreshed" + refreshed);
            } else {
                console.log("Token not refreshed, valid for " + Math.round(keycloakInstance.tokenParsed?.exp + keycloakInstance.timeSkew - new Date().getTime() / 1000) + " seconds");
            }
        })
        .catch(() => {
            console.log("Failed to refresh token");
        });
}

export default KeyCloakService;
