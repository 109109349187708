import config from "./config.json"
import packageConfig from "../package.json"

const apiURL =
    config.api.http +
    config.api.subdomain +
    config.api.domain +
    config.api.path +
    config.api.version;


export function getVersion() {
    return packageConfig.version
}

export function getClaimMailURL(mailId) {
    return apiURL + config.api.services.mail + "/"+ mailId+ "/" + config.api.services.claim
}
export function getAttachmentByIdURL(attachmentId) {
    return apiURL + config.api.services.attachment + "/"+ attachmentId
}
export function getMailsURL() {
    return apiURL + config.api.services.mails
}

export function getApiURL(controller) {
    return apiURL + controller
}

export function getMpuURL() {
    return apiURL +  config.api.services.mpu;
}
export function getContactsURL() {
    return apiURL +  config.api.services.contacts;
}

export function getAdvocardBusinessURL(own) {
    if (own){
        return apiURL + config.api.services.advocardBusiness + config.api.subServices.own
    }
    return apiURL + config.api.services.advocardBusiness
}

export function getAdvocardPrivateURL(own) {
    if (own){
        return apiURL + config.api.services.advocardPrivate + config.api.subServices.own
    }
    return apiURL + config.api.services.advocardPrivate
}

export function getAdvocardPrivateDocumentsURL(dataId) {
    return apiURL + config.api.services.advocardPrivate + config.api.subServices.own + "/"+ dataId+ "/" + config.api.services.documents
}
export function getAdvocardInternationalDocumentsURL(dataId) {
    return apiURL + config.api.services.advocardInternational + config.api.subServices.own + "/"+ dataId+ "/" + config.api.services.documents
}
export function getAragDocumentsURL(dataId) {
    return apiURL + config.api.services.arag + config.api.subServices.own + "/"+ dataId+ "/" + config.api.services.documents
}

export function getAdvocardPrivateDocumentIdURL(documentId) {
    return apiURL + config.api.services.advocardPrivateDataDocument + "/" + documentId
}
export function getAragDocumentIdURL(documentId) {
    return apiURL + config.api.services.aragDataDocument + "/" + documentId
}

export function getAdvocardInternationalDocumentIdURL(documentId) {
    return apiURL + config.api.services.advocardInternationalDataDocument + "/" + documentId
}

export function getAdvoPrivatePatchURL(dataId) {
    return apiURL + config.api.services.advocardPrivate + "/" + dataId
}
export function getAdvoInternationalPatchURL(dataId) {
    return apiURL + config.api.services.advocardInternational + "/" + dataId
}
export function getAragPatchURL(dataId) {
    return apiURL + config.api.services.arag + "/" + dataId
}
export function getStatisticsURL() {
    return apiURL + config.api.services.statistics + "/" +  config.api.services.caseCountPerThirtyMinutes
}
export function getStatisticsDataTypeURL() {
    return apiURL + config.api.services.statistics + "/" +  config.api.services.caseCountPerThirtyMinutesAndDataType
}

export function getAdvocardInternationalURL(own) {
    if (own){
        return apiURL + config.api.services.advocardInternational + config.api.subServices.own
    }
    return apiURL + config.api.services.advocardInternational
}
export function getRolandURL(own) {
    if (own){
        return apiURL + config.api.services.roland + config.api.subServices.own
    }
    return apiURL + config.api.services.roland
}
export function getAragURL(own) {
    if (own){
        return apiURL + config.api.services.arag + config.api.subServices.own
    }
    return apiURL + config.api.services.arag
}
export function getAppointmentURL(own) {
    if (own){
        return apiURL + config.api.services.appointment + config.api.subServices.own
    }
    return apiURL + config.api.services.appointment
}
export function getAppointmentsURL(own) {
    if (own){
        return apiURL + config.api.services.appointments + config.api.subServices.own
    }
    return apiURL + config.api.services.appointments
}
export function getTemplateAppointmentsURL() {
    return apiURL + config.api.services.templateAppointments
}
export function getTemplateAppointmentURL() {
    return apiURL + config.api.services.templateAppointment
}

export function getBillURL() {
    return apiURL + config.api.services.bill
}
export function getFilesOfBillsURL() {
    return apiURL + config.api.services.bill + "/" + config.api.services.files
}

export function getStagLegalUploadURL(key, insurance) {
    return apiURL + insurance + "Data/" + key + "/Document"
}


export function getLawyersURL() {
    return apiURL + config.api.services.users + "/" + config.api.services.lawyers
}
export function getLawyerURL(username) {
    return apiURL + config.api.services.user + "/" + config.api.services.lawyer + "/" + username
}
export function getLawyerByUsernameURL(username) {
    return apiURL + config.api.services.users + "/" + config.api.services.lawyer + "/" + username
}

export function requestMode() {
    return config.requestMode
}

export const keycloakConfig = {
    url: config.keycloak.url, // Your Keycloak server URL
    realm:  config.keycloak.realm, // Your realm
    clientId:  config.keycloak.clientId, // Your client id
};
