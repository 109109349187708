import { ReactiveStorage } from 'vue-reactive-storages';

const preset = {
    user: {
        KeycloakUUID: '',
        Username: '',
        roles: {
            Admin: false,
            Moderator: false,
            User: false,
        },
        permissions: {
            insurance: {
                advocardPrivateDataType: {
                    Telefonberatung: false,
                    Dokumentencheck: false,
                    Mediation: false,
                    VermittlungMediation: false,
                    Coaching: false,
                    VermittlungCoaching: false,
                    VermittlungFachberatung: false,
                    Fachberatung: false,
                    ChevalierVermittlung: false,
                },
                advocardBusinessDataType: {
                    Telefonberatung: false,
                },
                advocardInternationalDataType: {
                    Telefonberatung: false,
                    Dokumentencheck: false,
                    Mediation: false,
                    VermittlungMediation: false,
                    Coaching: false,
                    VermittlungCoaching: false,
                },
                aragDataType: {
                    Dokumentenpruefung: false,
                    OnlineBeratung: false,
                    Telefonberatung: false,
                    Telefonberatung247: false,
                    TelefonberatungInternational: false,
                    Vertragscheck: false,
                    ManagerRechtsschutz: false,
                    Strafrechtshotline: false,
                    ErstberatungVersicherungspruefung: false,
                    ErstberatungVersicherungspruefungVermittlung: false,
                    ErstberatungVersicherungspruefungFallback: false,
                    PruefungAnstellungsvertrag: false,
                    PruefungAnstellungsvertragVermittlung: false,
                    PruefungAnstellungsvertragFallback: false,
                    Servicepauschale: true,
                },
                rolandDataType: {
                    Telefonberatung: false,
                }
            },
            MPU: {
                MPU: false
            }
        },
    },
    app: {
        DarkTheme: true,
        Language: 'de',
        AuthenticationType: '',
    },
};
const config = {
    type: 'local',
    name: '$RStore',
};

export default ReactiveStorage.create(preset, config);
